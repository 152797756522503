.navbar {
  position: fixed;
  z-index: 998;
  top: 0px;
  left: 0px;
  width: 100vw;
}

.navburger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin: 3rem;
  margin-left: auto !important;
}

.navburger__slice {
  width: 30px;
  height: 5px;
  background: white;
  border-radius: 5px;
}
.navburger__slice::after,
.navburger__slice::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 5px;
  background: white;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.navburger__slice::before {
  transform: translateY(-10px);
}

.navburger__slice::after {
  transform: translateY(10px);
}

.navburger.open .navburger__slice {
  background: transparent;
}

.navburger.open .navburger__slice::before {
  background: black;
  transform: rotate(45deg);
  transform: translateX(35px, -35px);
}

.navburger.open .navburger__slice::after {
  background: black;
  transform: rotate(-45deg);
  transform: translateX(35px, 35px);
}

.nav-hide {
  position: fixed;
  z-index: -1;
  top: 0px;
  transition: all 1.5s ease-in-out;
  width: 100vw;
  height: 100vh;
  transform: translateY(-100vh);
  transition: all 1s ease-in-out;
}
.nav-hide.open {
  transform: translateY(0vh);
  transition: all 1s ease-in-out;
}

.navlogo {
  margin: 3rem;
}

.blur {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  z-index: -999;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-100vh);
  transition: all 1s ease-in-out;
}

.blur.open {
  transform: translateY(0vh);
  transition: all 1s ease-in-out;
}

.navbar-footer {
  font-family: "Source Sans Pro", sans-serif;
  padding: 2rem;
}

.headbar-blur {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
}

.headbar-blur.open {
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  background-color: transparent;
  transition: all 1s ease-in-out;
}

.blue .navburger__slice, .blue .navburger__slice::after, .blue .navburger__slice::before {
  background: #303f9f;
  transition: all 0.2s ease-in-out;
}


@media screen and (max-width: 600px) {
  .navlogo {
    margin: 1rem;
  }
  .navburger {
    margin: 1rem;
  }
  .nav-hide a{
    font-size: 25px;
  }
  .navbar-footer{
    margin-bottom: 4.5rem;
  }
}
