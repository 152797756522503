.cookieconsent-container{
    width: 100%;
    position: fixed;
    bottom: 1rem;
    left: 0px;
    display: flex;
    justify-content: center;
}
.cookieconsent{
    border-radius: 50px;
    left: 0px;
    width: 95%;
    flex-wrap: wrap;
    justify-content: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
}
.cookieconsent p{
    font-family: 'Oswald', sans-serif;
    padding: 2rem;
    padding-bottom: 0px;
    padding-top: 0px;
    font-size: 15px;
}

.privacypolicylink{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
    color: #303f9f;
}

.cookieconsent-button{
    width: 200px;
    height: 40px;
    border-radius: 10px;
    align-self: center;
    margin: 1rem;
    color: white;
    background-color: #303f9f;
    font-size: 1rem;
    border:none;
}

.cookieconsent-button:hover{
    background-color: #283593;
}
.cookieconsent-button:active{
    background-color: #5c6bc0;
    transition: all 0.2s ease-in-out;
}