@import url(https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer-container {
  background-color: #303f9f;
  color: white;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  font-family: "Source Sans Pro", sans-serif;
  margin-top: 10rem;
  text-align: center;
}

.footer-info {
  padding: 1rem;
}

.footer-menu {
  padding: 2rem;
}

.footer-menu a {
  font-size: 20px;
  color: white;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.sponsor_disclaimer {
    margin: 0.5rem;
}
.loghi_sponsor_footer {
  object-fit: cover;
  height: 70px;
  width: auto;
  margin: 1rem;
}
.loghi_sponsor_group {
  background: white;
  border-radius: 5rem;
  padding: 1rem;
  margin: 0.5rem;
}
.abuttonlike {
  background-color: #e8ca68;
  font-family: "Source Sans Pro", sans-serif;
  color: black;
  font-size: 16px;
  padding: 0.5rem;
  margin: 0.5rem;
}

@media screen and (max-width: 600px) {
  .footer-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .footer-menu{
    padding: 0rem;
  }
  .loghi_sponsor_footer {
    object-fit: cover;
    height: 30px;
    width: auto;
    margin: 1rem;
  }
}

img{
width: 40px;
height: 40px;
margin: 0.5rem;
}
.navbar {
  position: fixed;
  z-index: 998;
  top: 0px;
  left: 0px;
  width: 100vw;
}

.navburger {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin: 3rem;
  margin-left: auto !important;
}

.navburger__slice {
  width: 30px;
  height: 5px;
  background: white;
  border-radius: 5px;
}
.navburger__slice::after,
.navburger__slice::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 5px;
  background: white;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.navburger__slice::before {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.navburger__slice::after {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

.navburger.open .navburger__slice {
  background: transparent;
}

.navburger.open .navburger__slice::before {
  background: black;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform: translateX(35px, -35px);
          transform: translateX(35px, -35px);
}

.navburger.open .navburger__slice::after {
  background: black;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform: translateX(35px, 35px);
          transform: translateX(35px, 35px);
}

.nav-hide {
  position: fixed;
  z-index: -1;
  top: 0px;
  transition: all 1.5s ease-in-out;
  width: 100vw;
  height: 100vh;
  -webkit-transform: translateY(-100vh);
          transform: translateY(-100vh);
  transition: all 1s ease-in-out;
}
.nav-hide.open {
  -webkit-transform: translateY(0vh);
          transform: translateY(0vh);
  transition: all 1s ease-in-out;
}

.navlogo {
  margin: 3rem;
}

.blur {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  z-index: -999;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transform: translateY(-100vh);
          transform: translateY(-100vh);
  transition: all 1s ease-in-out;
}

.blur.open {
  -webkit-transform: translateY(0vh);
          transform: translateY(0vh);
  transition: all 1s ease-in-out;
}

.navbar-footer {
  font-family: "Source Sans Pro", sans-serif;
  padding: 2rem;
}

.headbar-blur {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
}

.headbar-blur.open {
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  background-color: transparent;
  transition: all 1s ease-in-out;
}

.blue .navburger__slice, .blue .navburger__slice::after, .blue .navburger__slice::before {
  background: #303f9f;
  transition: all 0.2s ease-in-out;
}


@media screen and (max-width: 600px) {
  .navlogo {
    margin: 1rem;
  }
  .navburger {
    margin: 1rem;
  }
  .nav-hide a{
    font-size: 25px;
  }
  .navbar-footer{
    margin-bottom: 4.5rem;
  }
}

.flex-row{
 display: -webkit-flex;
 display: flex;
 -webkit-flex-direction: row;
         flex-direction: row;
}

.flex-column{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.flex-wrap{
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.align-start{
    -webkit-align-self: flex-start;
            align-self: flex-start;
}
.align-center{
    -webkit-align-self: center;
            align-self: center;
}
.align-end{
    -webkit-align-self: flex-end;
            align-self: flex-end;
}

.justify-center{
    -webkit-justify-content: center;
            justify-content: center;
}
.justify-between{
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.position-topleft{
    position: absolute;
    left: 0px;
}

.position-topright{
    position: absolute;
    right: 0px;
}

.position-bottomleft{
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.position-bottomright{
    position: absolute;
    bottom: 0px;
    right: 0px;
}


.ml-1{
    margin-left: 1rem;
}
.ml-10{
    margin-left: 10rem;
}
.mt-auto{
    margin-top:auto;
}
.mt-2{
    margin-top: 2rem;
}

.mt-5{
    margin-top: 5rem;
}
.mt-10{
    margin-top: 10rem;
}

.mb-auto{
    margin-bottom:auto;
}

.logo-1{
    width: 120px;
}
.logo-10{
    width: 300px;
}

a{
    font-family: 'Rubik Mono One', sans-serif;
    font-size: 3rem;
    text-decoration: none;
    color: #1c4cce;
    margin-top: auto;
}

.h-100{
    height: 100%;
}

.w-100{
    width: 100%;
}


.h-100vh{
    height: 100vh;
}

.w-100vw{
    width: 100vw;
}






@media screen and (max-width: 600px) {
    .ml-10{
        margin-left: 1rem;
    }
}
.banner-container {
  width: 100vw;
  height: 100vh;
}
.banner {
  width: 100vw;
  height: 100vh;
  background-image: url(/static/media/newback.dacddff4.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
}

.caption {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: white;
  width: 40%;
  font-size: 2.5rem;
}

@media screen and (max-width: 600px) {
  .banner {
    background-position-x: -400px;
  }
}

.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.center {
  -webkit-justify-content: center;
          justify-content: center;
}
.column {
  -webkit-flex-direction: column;
          flex-direction: column;
}
.page-container {
  margin-top: 5rem;
}

.align-center{
  -webkit-align-items: center;
          align-items: center;
}
.text-container {
  display: -webkit-flex;
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-self: center;
          align-self: center;
  padding: 10rem;
}

.loghi_sponsor {
  object-fit: cover;
  height: 150px;
  width: auto;
  margin: 1rem;
}

.title {
  font-size: 30px;
  font-weight: 600;
}

.subtitle {
  font-size: 25px;
  font-weight: 600;
}

.ptitle {
  font-size: 20px;
  font-weight: 600;
}
.small_a {
  color: black;
  font-size: 20px;
}
.small_a_blue {
  color: #303f9f;
  font-size: 20px;
}
@media screen and (max-width: 600px) {
  .page-container {
    padding: 1rem;
  }
  .text-container {
    padding: 0rem;
  }
}

.cookieconsent-container{
    width: 100%;
    position: fixed;
    bottom: 1rem;
    left: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.cookieconsent{
    border-radius: 50px;
    left: 0px;
    width: 95%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
}
.cookieconsent p{
    font-family: 'Oswald', sans-serif;
    padding: 2rem;
    padding-bottom: 0px;
    padding-top: 0px;
    font-size: 15px;
}

.privacypolicylink{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
    color: #303f9f;
}

.cookieconsent-button{
    width: 200px;
    height: 40px;
    border-radius: 10px;
    -webkit-align-self: center;
            align-self: center;
    margin: 1rem;
    color: white;
    background-color: #303f9f;
    font-size: 1rem;
    border:none;
}

.cookieconsent-button:hover{
    background-color: #283593;
}
.cookieconsent-button:active{
    background-color: #5c6bc0;
    transition: all 0.2s ease-in-out;
}
.container-text{
    font-family: 'Source Sans Pro', sans-serif;
    width: 40%;
    font-size: 19px;
}

@media screen and (max-width: 600px) {
    .container-text{
        width: 80%;
    }
}

.fourimage-container{
    padding: 1rem;
}

.fourimage-container img{
    width: auto;
    max-width: 300px;
    height: 10rem;
}

@media screen and (max-width: 600px) {
    .fourimage-container img{
    }
}
.buybutton {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.buybutton a {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 50px;
  width: 300px;
  background-color: #f7d702;
}

.text,
.price {
  padding: 10px;
  color: black;
  font-size: 20px;
  font-family: "Source Sans Pro", sans-serif;
}

.startingfrom {
  position: absolute;
  top: 2px;
  left: 195px;
  font-size: 10px;
  color: black;
  font-family: "Source Sans Pro", sans-serif;
}

.service_container {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 10rem;
  width: 400px;
  height: auto;
  padding: 5rem;
}
.service_container img {
  width: 100%;
  height: auto;
}
.service_container .title {
  font-size: 25px;
  font-weight: lighter;
  text-align: center;
}
.service_dropdown {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 999;
  overflow-y: scroll;
}
.service_dropdown p {
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: pre-line;
}
.service_dropdown img {
  -webkit-align-self: center;
          align-self: center;
  width: 50%;
  height: auto;
}
.subtitle {
  -webkit-align-self: center;
          align-self: center;
}

.dropdown_on {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

