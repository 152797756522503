.container-text{
    font-family: 'Source Sans Pro', sans-serif;
    width: 40%;
    font-size: 19px;
}

@media screen and (max-width: 600px) {
    .container-text{
        width: 80%;
    }
}
