.buybutton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.buybutton a {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 300px;
  background-color: #f7d702;
}

.text,
.price {
  padding: 10px;
  color: black;
  font-size: 20px;
  font-family: "Source Sans Pro", sans-serif;
}

.startingfrom {
  position: absolute;
  top: 2px;
  left: 195px;
  font-size: 10px;
  color: black;
  font-family: "Source Sans Pro", sans-serif;
}
