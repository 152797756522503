.flex {
  display: flex;
  flex-wrap: wrap;
}
.center {
  justify-content: center;
}
.column {
  flex-direction: column;
}
.page-container {
  margin-top: 5rem;
}

.align-center{
  align-items: center;
}
.text-container {
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 10rem;
}

.loghi_sponsor {
  object-fit: cover;
  height: 150px;
  width: auto;
  margin: 1rem;
}

.title {
  font-size: 30px;
  font-weight: 600;
}

.subtitle {
  font-size: 25px;
  font-weight: 600;
}

.ptitle {
  font-size: 20px;
  font-weight: 600;
}
.small_a {
  color: black;
  font-size: 20px;
}
.small_a_blue {
  color: #303f9f;
  font-size: 20px;
}
@media screen and (max-width: 600px) {
  .page-container {
    padding: 1rem;
  }
  .text-container {
    padding: 0rem;
  }
}
