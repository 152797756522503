.footer-container {
  background-color: #303f9f;
  color: white;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  font-family: "Source Sans Pro", sans-serif;
  margin-top: 10rem;
  text-align: center;
}

.footer-info {
  padding: 1rem;
}

.footer-menu {
  padding: 2rem;
}

.footer-menu a {
  font-size: 20px;
  color: white;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.sponsor_disclaimer {
    margin: 0.5rem;
}
.loghi_sponsor_footer {
  object-fit: cover;
  height: 70px;
  width: auto;
  margin: 1rem;
}
.loghi_sponsor_group {
  background: white;
  border-radius: 5rem;
  padding: 1rem;
  margin: 0.5rem;
}
.abuttonlike {
  background-color: #e8ca68;
  font-family: "Source Sans Pro", sans-serif;
  color: black;
  font-size: 16px;
  padding: 0.5rem;
  margin: 0.5rem;
}

@media screen and (max-width: 600px) {
  .footer-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .footer-menu{
    padding: 0rem;
  }
  .loghi_sponsor_footer {
    object-fit: cover;
    height: 30px;
    width: auto;
    margin: 1rem;
  }
}
