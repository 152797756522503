.service_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
  width: 400px;
  height: auto;
  padding: 5rem;
}
.service_container img {
  width: 100%;
  height: auto;
}
.service_container .title {
  font-size: 25px;
  font-weight: lighter;
  text-align: center;
}
.service_dropdown {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 999;
  overflow-y: scroll;
}
.service_dropdown p {
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: pre-line;
}
.service_dropdown img {
  align-self: center;
  width: 50%;
  height: auto;
}
.subtitle {
  align-self: center;
}

.dropdown_on {
  display: flex;
  flex-direction: column;
}
