.fourimage-container{
    padding: 1rem;
}

.fourimage-container img{
    width: auto;
    max-width: 300px;
    height: 10rem;
}

@media screen and (max-width: 600px) {
    .fourimage-container img{
    }
}