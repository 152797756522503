.banner-container {
  width: 100vw;
  height: 100vh;
}
.banner {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/image/newback.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}

.caption {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: white;
  width: 40%;
  font-size: 2.5rem;
}

@media screen and (max-width: 600px) {
  .banner {
    background-position-x: -400px;
  }
}
