.flex-row{
 display: flex;
 flex-direction: row;
}

.flex-column{
    display: flex;
    flex-direction: column;
}
.flex-wrap{
    flex-wrap: wrap;
}
.align-start{
    align-self: flex-start;
}
.align-center{
    align-self: center;
}
.align-end{
    align-self: flex-end;
}

.justify-center{
    justify-content: center;
}
.justify-between{
    justify-content: space-between;
}

.position-topleft{
    position: absolute;
    left: 0px;
}

.position-topright{
    position: absolute;
    right: 0px;
}

.position-bottomleft{
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.position-bottomright{
    position: absolute;
    bottom: 0px;
    right: 0px;
}


.ml-1{
    margin-left: 1rem;
}
.ml-10{
    margin-left: 10rem;
}
.mt-auto{
    margin-top:auto;
}
.mt-2{
    margin-top: 2rem;
}

.mt-5{
    margin-top: 5rem;
}
.mt-10{
    margin-top: 10rem;
}

.mb-auto{
    margin-bottom:auto;
}

.logo-1{
    width: 120px;
}
.logo-10{
    width: 300px;
}

a{
    font-family: 'Rubik Mono One', sans-serif;
    font-size: 3rem;
    text-decoration: none;
    color: #1c4cce;
    margin-top: auto;
}

.h-100{
    height: 100%;
}

.w-100{
    width: 100%;
}


.h-100vh{
    height: 100vh;
}

.w-100vw{
    width: 100vw;
}






@media screen and (max-width: 600px) {
    .ml-10{
        margin-left: 1rem;
    }
}